import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on2 = ref.on;
var attrs2 = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs2,false),on2),[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Refuser la facture")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v(" Refuser la facture ")])],1),_c(VCardText,[_c(VTextarea,{attrs:{"label":"Raison du refus"},model:{value:(_vm.raison),callback:function ($$v) {_vm.raison=$$v},expression:"raison"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.updating},on:{"click":_vm.refusePayment}},[_vm._v("Refuser")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }